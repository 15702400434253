<template>
    <div>
        <section id="page-title" class="page-title-parallax page-title-dark" style="background-image: url('/assets/images/about/parallax.jpg'); padding: 120px 0;" data-bottom-top="background-position:0px 300px;" data-top-bottom="background-position:0px -300px;">

			<div class="container clearfix">
				<h1>Job Openings</h1>
				<span>Join our Fabulous Team of Intelligent Individuals</span>
				<ol class="breadcrumb">
					<li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
					<li class="breadcrumb-item active" aria-current="page">Jobs</li>
				</ol>
			</div>

		</section><!-- #page-title end -->
        <!-- Content
		============================================= -->
		<section id="content">
			<div class="content-wrap">
				<div class="container clearfix">
                    
					<div class="row col-mb-50">
                        <!-- sidebar -->
                        <div class="col-md-4">
                            <Sidebar/>
                        </div>
                        <!-- JD  -->
						<div class="col-md-8">
							<div>
                                <h3 style="width: 100%;padding: 0 0 0.75rem;border-bottom: 2px solid #1ABC9C;">QA Engineer
                                <router-link to="/careers/QA-Engineer/form" class="button button-3d m-0 button-primary" style="float:right">Apply Now</router-link></h3>
                                <h4>The Role</h4>
                                <p>The QA Automation Engineer is a highly motivated individual who will help ensure the high-level of quality of the sales engagement software used by sales professionals. You'll work closely with the QA Engineering Manager to develop and maintain several automated test suites, as well as maintain and monitor daily test runs in a pipeline and report defects as necessary.</p>
                                <p>We know it can be tricky to apply for roles, wondering if the position is right for you and if you and your experience are suitable for the role. Many people won't apply for roles unless they feel that they tick every single box. At eFlex, we look for many different skills and abilities, and we're always looking for how new team members can add to eFlex and our culture. So if you don't think you quite meet all of the skills listed, we'd still love to hear from you!</p>

                                <div class="accordion accordion-bg">

                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            What you'll be doing
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-ok"></i>Design and implement Test Automation frameworks using Python, SQL, Airflow.</li>
                                            <li><i class="icon-ok"></i>Develop test strategies, plans, test cases, and engineering best practices related to software test engineering, both manual and automated testing with a specific focus on data quality.</li>
                                            <li><i class="icon-ok"></i>Using Tableau, Python, Databricks and DataDog create active quality monitoring for data pipelines and processes.</li>
                                            <li><i class="icon-ok"></i>Work closely with data engineers, data scientists and analysts to inform solution designs in terms of testability.</li>
                                            <li><i class="icon-ok"></i>Building and automating testing frameworks around data ingestion pipelines and active monitoring.</li>
                                            <li><i class="icon-ok"></i>Build and maintain testing frameworks for big data environments that are highly secure, scalable, flexible, and performant using appropriate SQL technologies.</li>
                                            <li><i class="icon-ok"></i>Support data governance and data profiling efforts to ensure data quality and proper meta-data documentation for data lineage.</li>
                                            <li><i class="icon-ok"></i>Creating quality metrics to evaluate data pipelines, visualizations, and dashboards.</li>
                                            <li><i class="icon-ok"></i>Implement and execute test strategies on all supported platforms and languages to help improve the overall quality and test code coverage.</li>
                                            <li><i class="icon-ok"></i>Design and develop integration, regression, and stress tests using industry-standard tools.</li>
                                            <li><i class="icon-ok"></i>Collaborate with Product Management and Engineers to understand requirements, translate them into test cases and determine product quality goals and measurements.</li>
                                            <li><i class="icon-ok"></i>Reproduce, isolate, and debug issues, providing detailed bug reports.</li>
                                            <li><i class="icon-ok"></i>Develops and enhances the test infrastructure and continuous integration framework used across teams.</li>
                                            <li><i class="icon-ok"></i>Validate data pipelines and data processing jobs that collect data from disparate systems and store it in internal databases.</li>
                                            <li><i class="icon-ok"></i>Identify edge cases that can potentially break the data pipelines or compromise data quality or integrity.</li>
                                        </ul>
                                    </div>

                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            Minimum Requirements
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-ok"></i>5+ years experience in the QA field.</li>
                                            <li><i class="icon-ok"></i>3+ years experience writing Javascript based automated test scripts.</li>
                                            <li><i class="icon-ok"></i>Experience with GitHub.</li>
                                            <li><i class="icon-ok"></i>Experience writing tests and creating collections in Postman.</li>
                                            <li><i class="icon-ok"></i>Experience with running tools and scripts from the command line.</li>
                                            <li><i class="icon-ok"></i>Excellent verbal and written communication skills.</li>
                                            <li><i class="icon-ok"></i>Strong organization skills and attention to detail.</li>
                                            <li><i class="icon-ok"></i>Experience with Jira or other Kanban board style software.</li>
                                            <li><i class="icon-ok"></i>Experience testing web-based applications.</li>
                                            <li><i class="icon-ok"></i>Experience using Chrome Dev Tools for inspecting browser errors and issues.</li>
                                            <li><i class="icon-ok"></i>Prior remote, work-from-home experience in a QA role.</li>
                                            <li><i class="icon-ok"></i>You have an advantage if you have:</li>
                                            <li>
                                                <ul class="iconlist iconlist-color mb-0">
                                                    <li><i class="icon-plus"></i>1+ years current experience with Cypress</li>
                                                    <li><i class="icon-plus"></i>Hands-on experience with AWS CodeBuild and CodePipeline</li>
                                                    <li><i class="icon-plus"></i>Prior work in a SaaS company.</li>
                                                    <li><i class="icon-plus"></i>Knowledge of SQL and how to run database queries.</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    
                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            What you'll enjoy at eFlex
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-plus-sign"></i>We live our values as playmakers, obsessed with learning, care personally about our colleagues and partners, are radically open-minded, and take pride in everything we do.</li>
                                            <li><i class="icon-plus-sign"></i>We offer a Competitive Salary.</li>
                                            <li><i class="icon-plus-sign"></i>Work-from-home setup as we prepare to provide a safe environment for our employees. Our Future of Work plan is to move towards a hybrid work model (in-office & from home).</li>
                                            <li><i class="icon-plus-sign"></i>We offer comprehensive HMO and optical reimbursements.</li>
                                            <li><i class="icon-plus-sign"></i>Recharge and go on your next getaway or simply take time off for yourself through our flexible personal & sick days. We want our team to be happy and healthy :)</li>
                                            <li><i class="icon-plus-sign"></i>We support our employee's career growth and development by offering opportunities for promotion.</li>
                                        </ul>
                                    </div>

                                    <div class="accordion-header">
                                        <div class="accordion-title">
                                            About eFlexervices
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <p>eFlexervices is a 23-year-old BPO company providing exceptional quality and unwavering trust. Our partnership approach to the business allows us to match the right talent to every organization we support. We invest in people to optimize performance and maximize efficiency. We work hard to produce the highest possible results for our partners.</p>
                                        <p>Performance is the underlying foundation that drives eFlexervices. We deliver the metrics our partners expect through proper recruitment and heavy investment in the right people.</p>
                                        <p>Deep integration within our partners' organizations drives our team to take ownership of their work. This stakeholder mindset pushes higher performance, better quality, and longer retention.</p>
                                    </div>
                                    <router-link to="/careers/QA-Engineer/form" class="button button-3d m-0 button-primary" style="float:left">Apply Now</router-link>
                                </div>
                                <div class="divider divider-sm"></div>
                            </div>
						</div>
                        
                    </div>

				</div>
			</div>
		</section><!-- #content end -->

    </div>
</template>

<script>
import Sidebar from '@/components/CareerNav.vue'
export default {
    data() {
        return {
            
        }
    },
    components: {
        Sidebar
    }
}
</script>